import { createEffect, createEvent, createStore, sample, scopeBind } from 'effector';
import { fetchData, geoServiceOptions } from '@/shared/api/data-fetcher';
import { LocalStorageService, LSKeys } from '@/shared/utils/local-storage';
import { consoleDev } from '@/shared/app/consoleDev';
import { $arenaDataStore } from '@/app.model';
import { externalScriptLoaded, externalScriptsUpdated } from '@/features/external-scripts/model';
import { TExternalScriptRaw } from '@/shared/api/arena-data';

interface GeoIpResponse {
    ip: string;
    country_code: string;
    country_name: string;
    region_code: string;
    region_name: string;
    city: string;
    zip_code: string;
    time_zone: string;
    latitude: number;
    longitude: number;
    metro_code: number;
}
export const fetchUserGeoData = async (): Promise<GeoIpResponse> =>
    await fetchData(process.env.GEO_SERVICE_URL, geoServiceOptions);

export const $userCountry = createStore<string | null>(null);
export const fetchUserCountryFx = createEffect<void, GeoIpResponse>(fetchUserGeoData);
sample({
    clock: fetchUserCountryFx.doneData,
    filter: (data) => !!data,
    fn: (data: GeoIpResponse) => {
        return data.country_code;
    },
    target: $userCountry,
});

export enum CUSTOM_BEHAVIOUR_REGIONS {
    //USA, TODO: what about California with their CCPA?
    US = 'US',
    AS = 'AS', //American Samoa
    GU = 'GU', //Guam
    MP = 'MP', //Northern Mariana Islands
    PR = 'PR', //Puerto Rico
    UM = 'UM', //U.S. Minor Outlying Islands
    VI = 'VI', //U.S. Virgin Islands

    //EEA included outermost regions / GDPR
    AT = 'AT', //Austria
    AX = 'AX', //Åland Islands
    BE = 'BE', //Belgiuutilsm
    BG = 'BG', //Bulgaria
    CY = 'CY', //Cyprus
    CZ = 'CZ', //Czech Republic
    DE = 'DE', //Germany
    DK = 'DK', //Denmark
    EE = 'EE', //Estonia
    EL = 'EL', //Greece
    ES = 'ES', //Spain
    EU = 'EU', //European Union
    FI = 'FI', //Finland
    FR = 'FR', //France
    GB = 'GB', //United Kingdom (Great Britain)
    GF = 'GF', //French Guiana
    GI = 'GI', //Gibraltar
    GP = 'GP', //Guadeloupe
    GR = 'GR', //Greece
    HR = 'HR', //Croatia
    HU = 'HU', //Hungary
    IE = 'IE', //Ireland, Republic of (EIRE)
    IS = 'IS', //Iceland
    IT = 'IT', //Italy
    LI = 'LI', //Liechtenstein
    LT = 'LT', //Lithuania
    LU = 'LU', //Luxembourg
    LV = 'LV', //Latvia
    ME = 'ME', //Montenegro
    MF = 'MF', //Saint Martin
    MQ = 'MQ', //Martinique
    MT = 'MT', //Malta
    NL = 'NL', //Netherlands
    NO = 'NO', //Norway
    PL = 'PL', //Poland
    PT = 'PT', //Portugal
    RE = 'RE', //Réunion
    RO = 'RO', //Romania
    SE = 'SE', //Sweden
    SI = 'SI', //Slovenia
    SK = 'SK', //Slovakia
    YT = 'YT', //Mayotte
    UK = 'UK', //United Kingdom (Great Britain)

    //No consent regions, disabling ads and analytics
    //China / PIPL
    CN = 'CN', //China
    HK = 'HK', //Hong Kong
    MO = 'MO', //Macao
    //Not supported by Quantcast yet
    BR = 'BR', //Brazil
}

type OutOfBand = {
    allowedVendors: Record<string, unknown>;
    disclosedVendors: Record<string, unknown>;
};

type Purpose = {
    consents: Record<string, unknown>;
    legitimateInterests: Record<string, unknown>;
};

type Vendor = {
    consents: Record<string, unknown>;
    legitimateInterests: Record<string, unknown>;
};

type SpecialFeatureOptins = {
    [key: string]: unknown;
};

type Publisher = {
    consents: Record<string, unknown>;
    legitimateInterests: Record<string, unknown>;
    customPurpose: Purpose;
    restrictions: Record<string, unknown>;
};

type CMPData = {
    cmpId: number;
    cmpVersion: number;
    gdprApplies: boolean;
    tcfPolicyVersion: number;
    tcString: string;
    listenerId: number;
    eventStatus: string;
    cmpStatus: string;
    isServiceSpecific: boolean;
    useNonStandardTexts: boolean;
    publisherCC: string;
    purposeOneTreatment: boolean;
    outOfBand: OutOfBand;
    purpose: Purpose;
    vendor: Vendor;
    specialFeatureOptins: SpecialFeatureOptins;
    publisher: Publisher;
};
const lsTargetingConsent = LocalStorageService.getItem(LSKeys.targetingConsent, true);
const lsGdprConsent = LocalStorageService.getItem(LSKeys.gdprCookieConsent, true);
const INIT_TARGETING_CONSENT = lsTargetingConsent === 'true';
const INIT_GDPR_CONSENT = lsGdprConsent === 'true';
export const $targetingConsentStore = createStore<boolean | null>(INIT_TARGETING_CONSENT);
export const targetingConsentUpdated = createEvent<boolean | null>();
export const $gdprConsentStore = createStore<boolean | null>(INIT_GDPR_CONSENT);
export const gdprConsentUpdated = createEvent<boolean | null>();

const CHINA_REGIONS = new Set([CUSTOM_BEHAVIOUR_REGIONS.CN, CUSTOM_BEHAVIOUR_REGIONS.HK, CUSTOM_BEHAVIOUR_REGIONS.MO]);
const NO_CMP_REGIONS = new Set([...CHINA_REGIONS, CUSTOM_BEHAVIOUR_REGIONS.BR]);
const US_REGIONS = new Set([
    CUSTOM_BEHAVIOUR_REGIONS.US,
    CUSTOM_BEHAVIOUR_REGIONS.AS,
    CUSTOM_BEHAVIOUR_REGIONS.GU,
    CUSTOM_BEHAVIOUR_REGIONS.MP,
    CUSTOM_BEHAVIOUR_REGIONS.PR,
    CUSTOM_BEHAVIOUR_REGIONS.UM,
    CUSTOM_BEHAVIOUR_REGIONS.VI,
]);

const GDPR_REGIONS = new Set<string>([
    CUSTOM_BEHAVIOUR_REGIONS.AT,
    CUSTOM_BEHAVIOUR_REGIONS.AX,
    CUSTOM_BEHAVIOUR_REGIONS.BE,
    CUSTOM_BEHAVIOUR_REGIONS.BG,
    CUSTOM_BEHAVIOUR_REGIONS.CY,
    CUSTOM_BEHAVIOUR_REGIONS.CZ,
    CUSTOM_BEHAVIOUR_REGIONS.DE,
    CUSTOM_BEHAVIOUR_REGIONS.DK,
    CUSTOM_BEHAVIOUR_REGIONS.EE,
    CUSTOM_BEHAVIOUR_REGIONS.EL,
    CUSTOM_BEHAVIOUR_REGIONS.ES,
    CUSTOM_BEHAVIOUR_REGIONS.EU,
    CUSTOM_BEHAVIOUR_REGIONS.FI,
    CUSTOM_BEHAVIOUR_REGIONS.FR,
    CUSTOM_BEHAVIOUR_REGIONS.GB,
    CUSTOM_BEHAVIOUR_REGIONS.GF,
    CUSTOM_BEHAVIOUR_REGIONS.GI,
    CUSTOM_BEHAVIOUR_REGIONS.GP,
    CUSTOM_BEHAVIOUR_REGIONS.GR,
    CUSTOM_BEHAVIOUR_REGIONS.HR,
    CUSTOM_BEHAVIOUR_REGIONS.HU,
    CUSTOM_BEHAVIOUR_REGIONS.IE,
    CUSTOM_BEHAVIOUR_REGIONS.IS,
    CUSTOM_BEHAVIOUR_REGIONS.IT,
    CUSTOM_BEHAVIOUR_REGIONS.LI,
    CUSTOM_BEHAVIOUR_REGIONS.LT,
    CUSTOM_BEHAVIOUR_REGIONS.LU,
    CUSTOM_BEHAVIOUR_REGIONS.LV,
    CUSTOM_BEHAVIOUR_REGIONS.ME,
    CUSTOM_BEHAVIOUR_REGIONS.MF,
    CUSTOM_BEHAVIOUR_REGIONS.MQ,
    CUSTOM_BEHAVIOUR_REGIONS.MT,
    CUSTOM_BEHAVIOUR_REGIONS.NL,
    CUSTOM_BEHAVIOUR_REGIONS.NO,
    CUSTOM_BEHAVIOUR_REGIONS.PL,
    CUSTOM_BEHAVIOUR_REGIONS.PT,
    CUSTOM_BEHAVIOUR_REGIONS.RE,
    CUSTOM_BEHAVIOUR_REGIONS.RO,
    CUSTOM_BEHAVIOUR_REGIONS.SE,
    CUSTOM_BEHAVIOUR_REGIONS.SI,
    CUSTOM_BEHAVIOUR_REGIONS.SK,
    CUSTOM_BEHAVIOUR_REGIONS.YT,
    CUSTOM_BEHAVIOUR_REGIONS.UK,
]);

export const $isGDPRApplies = createStore<boolean | null>(null).on(fetchUserCountryFx.doneData, (_, data) =>
    GDPR_REGIONS.has(data.country_code as CUSTOM_BEHAVIOUR_REGIONS)
);
export const $isCMPDisabled = createStore<boolean | null>(null).on(fetchUserCountryFx.doneData, (_, data) =>
    NO_CMP_REGIONS.has(data.country_code as CUSTOM_BEHAVIOUR_REGIONS)
);

export const $isUS = createStore<boolean | null>(null).on(fetchUserCountryFx.doneData, (_, data) =>
    US_REGIONS.has(data.country_code as CUSTOM_BEHAVIOUR_REGIONS)
);

sample({
    clock: $isCMPDisabled,
    source: $arenaDataStore,
    filter: ({ external_scripts }, isCMPDisabled) => Boolean(external_scripts?.length) && isCMPDisabled !== null,
    fn: ({ external_scripts }, isCMPDisabled) => {
        return isCMPDisabled
            ? (external_scripts as TExternalScriptRaw[]).filter((s) => !s.name.toLowerCase().includes('quantcast'))
            : external_scripts;
    },
    target: externalScriptsUpdated,
});

sample({
    clock: $isCMPDisabled,
    source: $targetingConsentStore,
    fn: (targetingConsent, isCMPDisabled) => {
        if (isCMPDisabled) {
            return false;
        }

        return targetingConsent;
    },
    target: targetingConsentUpdated,
});

sample({
    clock: $isCMPDisabled,
    source: $gdprConsentStore,
    fn: (gdprConsent, isCMPDisabled) => {
        if (isCMPDisabled) {
            return false;
        }

        return gdprConsent;
    },
    target: gdprConsentUpdated,
});

export const gdprCmpUpdated = createEvent<CMPData>();
export const gdprCmpStartedFx = createEffect(() => {
    //check if __tcfapi become available
    const pollInterval = setInterval(() => {
        if (window.__tcfapi) {
            clearInterval(pollInterval);
            const gdprCmpUpdatedBound = scopeBind(gdprCmpUpdated, { safe: true });
            window.__tcfapi('addEventListener', 2, gdprCmpUpdatedBound);
        }
    }, 100);
});
sample({
    clock: externalScriptLoaded,
    source: $isUS,
    filter: (isUS, scriptName) => scriptName.toLowerCase().includes('quantcast') && isUS === false,
    target: gdprCmpStartedFx,
});

sample({
    clock: gdprCmpUpdated,
    fn: (tcData) => {
        if (tcData.eventStatus !== 'useractioncomplete') {
            return null;
        }
        let targetingConsent = null;
        try {
            if (tcData.gdprApplies) {
                targetingConsent = Boolean(tcData.purpose.consents[1]);
            } else {
                targetingConsent = true;
            }
        } catch (ex) {
            consoleDev(ex, 'Exception: targetingConsent = false');
            targetingConsent = false;
        }

        return targetingConsent;
    },
    target: targetingConsentUpdated,
});

sample({
    clock: gdprCmpUpdated,
    fn: (tcData) => {
        if (tcData.eventStatus !== 'useractioncomplete') {
            return null;
        }
        let consent = null;
        try {
            if (tcData.gdprApplies) {
                consent = Boolean(tcData.purpose.consents[1]) && Boolean(tcData.vendor.consents[11]);
            } else {
                consent = true;
            }
        } catch (ex) {
            consent = false;
            consoleDev('Exception: gdprConsent  =' + LocalStorageService.getItem(LSKeys.gdprCookieConsent, true));
        }
        return consent;
    },
    target: gdprConsentUpdated,
});

sample({
    clock: targetingConsentUpdated,
    source: $isGDPRApplies,
    filter: (source, consent) => consent !== null,
    fn: (isGDPRApplies, targetingConsent) => {
        consoleDev(`gdprApplies = ${isGDPRApplies}, targetingConsent = ${targetingConsent}`);
        LocalStorageService.setItem(LSKeys.targetingConsent, targetingConsent, true);
        return targetingConsent;
    },
    target: $targetingConsentStore,
});

sample({
    clock: gdprConsentUpdated,
    source: $isGDPRApplies,
    filter: (source, consent) => consent !== null,
    fn: (isGDPRApplies, gdprConsent) => {
        consoleDev(`gdprApplies = ${isGDPRApplies}, gdprConsent = ${gdprConsent}`);
        LocalStorageService.setItem(LSKeys.gdprCookieConsent, gdprConsent, true);
        return gdprConsent;
    },
    target: $gdprConsentStore,
});

export const openGDPRCmpPopupFx = createEffect(() => {
    if (window.__tcfapi) {
        window.__tcfapi('displayConsentUi', 2, function () {});
    }
});
export const openUSPCmpPopupFx = createEffect(() => {
    if (window.__uspapi) {
        window.__uspapi('displayUspUi');
    }
});
