
export const getSearchParams = (queryString: string, name: string): string => {
    const search = new URLSearchParams(queryString);
    return search.get(name) ?? '';
};

export const delayNavigation = (event: any, delay = 400) => {
    setTimeout(() => {
        const href = event.target.getAttribute('href');
        const target = event.target.getAttribute('target');

        if (href) {
            if (target === '_blank') {
                window.open(href);
            } else {
                window.location.href = href;
            }
        }
    }, delay);
};

export const buildHrefLocale = (href: string, locale: string) => {
    if (href.startsWith('/')) {
        return `/${locale}${href}`;
    } else if (href.startsWith('http')) {
        return href;
    } else {
        return `/${locale}/${href}`;
    }
};

export const cleanQueryParams = (queryParams: URLSearchParams, toExclude: string[]): boolean => {
    let hasChanged = false;
    // Create an array to hold the keys that need to be deleted
    const keysToDelete: string[] = [];
    // First pass: Collect the keys to delete
    queryParams.forEach((value, key) => {
        if (toExclude.some((unwanted) => key === unwanted || key.startsWith(unwanted))) {
            keysToDelete.push(key); // Mark this key for deletion
        }
    });
    // Second pass: Actually delete the keys
    keysToDelete.forEach((key) => {
        queryParams.delete(key); // Remove the key and its value
        hasChanged = true;
    });
    return hasChanged; // Return whether any changes were made
};
