import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { detectIncognito } from 'detectincognitojs';
import { LocalStorageService } from '../../../shared/utils/local-storage';
import { initFPStore } from './model';

class FingerprintServiceBase {
    lsPropName = 'FP_VISITOR';

    async init() {
        const result = JSON.stringify(await this.detect());
        LocalStorageService.setItem(this.lsPropName, result);
        return result;
    }

    async detect() {
        const detected = this.detected;
        const fingerprintId = await this.getFingerprint();
        const fpVisitorId = detected && detected?.fpVisitorId !== fingerprintId ? detected.fpVisitorId : fingerprintId;
        const fpIncognito = await this.isIncognito();

        const result = { fpVisitorId, fpIncognito };
        console.info('FingerprintService', 'detect!', result);
        return result;
    }

    get detected() {
        const detectedJSON = LocalStorageService.getItem(this.lsPropName);
        let detected = initFPStore;
        try {
            detected = detectedJSON ? JSON.parse(detectedJSON) : null;
        } catch (e) {
            console.error(e);
        }
        console.info('FingerprintService', 'get detected', detected);
        return detected;
    }

    async getFingerprint() {
        // Initialize an agent at application startup.
        const fingerprintPromise = FingerprintJS.load();
        const FP = await fingerprintPromise;
        const result = await FP.get();
        return result?.visitorId;
    }

    async isIncognito() {
        const result = await detectIncognito();
        return result?.isPrivate;
    }
}

export const FingerprintService = new FingerprintServiceBase();
